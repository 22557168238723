export const FARMER_DATA = [
  {
    title: "Shantaram Ranjane",
    village: "Ghoteghar",
    area: "Mahabaleshwar Dist Satar",
    img: "/assets/img/Shantaram-Ranjane.jpeg",
  },
  {
    title: "Dattu Bhiku Ranjane",
    village: "Ghoteghar",
    area: "Mahabaleshwar Dist Satar",
    img: "/assets/img/Dattu-Bhiku-Ranjane.jpeg",
  },
  {
    title: "Digambar Ranjane",
    village: "Ghoteghar",
    area: "Mahabaleshwar Dist Satar",
    img: "/assets/img/Digambar-Ranjane.jpeg",
  },
  {
    title: "Shailesh Bhilare",
    village: "Khingar",
    area: "Mahabaleshwar Dist Satar",
    img: "/assets/img/Digambar-Ranjane.jpeg",
  },
];
