import React from "react";
import Module from "../assets/module.png";

function Showcase() {
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="showcase-content mt-1 text-center">
            <h1>Solar Module Traceablity</h1>
            {/* <p className="mt-4 mb-2">Ajwain Honey - 250ml</p> */}
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <div>
                <h2 style={{ fontSize: "16px", textAlign: "left" }}>
                  Enter Module Number
                </h2>
                <input type="number" />
                <button className="showcase-btn ml-1">Show Trace</button>
              </div>
            </div>
            {/* <div className="text-center">
              <button
                className="showcase-btn btn-primary mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Visit our website
              </button>
            </div> */}
          </div>
        </div>
        <div className="col-lg-12 text-center mt-3 col-md-12 col-sm-12">
          <img className="honey-pic" src={Module} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Showcase;
