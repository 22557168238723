import "../assets/css/farmers.css";
import { useEffect } from "react";
import { FARMER_DATA } from "../constants/farmers";
import Footer from "../components/Footer";

function Farmers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="header">
        <div className="overlay"></div>
      </div>
      <div className="main-section">
        <h1 className="text-center">Farmers</h1>
        {/* <p className="text-center text-justify">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem
          culpa laboriosam debitis quae quos officiis odit incidunt tempore
          neque iusto.
        </p> */}
      </div>
      <div className="container mb-2">
        <div className="row">
          {FARMER_DATA.map((data) => (
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="farmers-cards-container">
                <div className="farmer-card pt-3">
                  <div
                    style={{
                      background: `url(${data.img})`,
                      backgroundSize: "cover",
                    }}
                    className="farmer-card-img-circle"
                  ></div>
                  <div className="farmer-card-content text-center px-4 mt-4 pb-2">
                    <h1>{data.title}</h1>
                    <p style={{ fontStyle: "italic" }}>
                      {data.village},&nbsp;{data.area}, India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Farmers;
