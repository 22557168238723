import React from "react";
import logo from "../assets/adani-logo.png";

export const Logo = () => {
  return (
    <div className="logo-div mt-3 text-center">
      <div className="container">
        <img className="logo" src={logo} alt="" />
      </div>
    </div>
  );
};
