import React from "react";
import shape from "../assets/bee-flip.png";

function Footer() {
  return (
    <footer style={{ position: "relative" }}>
      {/* <img style={{ position: "absolute", top: "-6rem" }} src={shape} alt="" /> */}
      <div className="container pb-3">
        <div className="row">
          <div className="social-section mb-2">
            <a href="https://twitter.com/honeyveda_in">
              {" "}
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/honeyveda.in/">
              {" "}
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/honeyveda.in/">
              {" "}
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="powered-footer text-center pb-2">Powered by</div>
        <div className="text-center">
          <a href="https://emertech.io/">
            <img
              className="footer-logo"
              src="/assets/img/emertech-logo-main.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
