import React from "react";
import Footer from "../components/Footer";
import { Logo } from "../components/Logo";
import { Shapes } from "../components/Shapes";
import Showcase from "../components/Showcase";
import Timeline from "../components/Timeline";

export const Home = () => {
  return (
    <>
      {/* <Shapes /> */}
      <Logo />
      <Showcase />
      <Timeline />
      <Footer />
    </>
  );
};
