import React from "react";
import { TIMELINE_DATA } from "../constants/timeline";
// import FarmerModal from "./FarmerModal";
import Solar from "../assets/solar.png";

function Timeline() {
  return (
    <>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill=""
          d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
        ></path>
      </svg> */}
      <div className="ag-timeline-block">
        <div className="ag-timeline_title-box">
          <div className="ag-timeline_title">
            Here is the trace for your module
          </div>
          {/* <div className="mt-2 mb-2 ag-timeline_title-para text-justify">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            cupiditate quia ad fuga fugiat.
          </div> */}
          {/* <div className="ag-timeline_tagline">Trace</div> */}
        </div>
        <section className="ag-section">
          <div className="ag-format-container">
            <div className="js-timeline ag-timeline">
              <div className="js-timeline_line ag-timeline_line">
                <div className="js-timeline_line-progress ag-timeline_line-progress"></div>
              </div>
              <div className="ag-timeline_list">
                {TIMELINE_DATA.map((item, i) => (
                  <div class="js-timeline_item js-ag-show ag-timeline_item">
                    <div class="ag-timeline-card_item">
                      <div class="ag-timeline-card_inner">
                        <div class="ag-timeline-card_img-box"></div>
                        <div class="ag-timeline-card_info">
                          <div class="ag-timeline-card_meta">
                            {item?.title}
                            {<div class="subtitle">{item?.subtitle}</div>}
                          </div>
                          <div class="ag-timeline-card_desc mb-4 text-justify">
                            {item?.description}
                          </div>
                          {/* {
                            <a
                              href="https://honeyveda.in/"
                              class="card-btn btn-primary mt-1"
                            >
                              Visit Website
                            </a>
                          } */}
                        </div>
                      </div>
                      <div class="ag-timeline-card_arrow"></div>
                    </div>
                    <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                      <div class="ag-timeline-card_point">
                        <img
                          src={item?.img}
                          class="ag-timeline-card_img"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="ag-timeline-card_meta-box"></div>
                  </div>
                ))}
                {/* <div className="Thanks-content mt-1 text-center">
                  <h1>Thank you for purchase!</h1>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <FarmerModal /> */}
      {/* <div style={{ width: "150px", position: "absolute", left: "-10px" }}>
        <img className="flowers" src={Solar} alt="" />
      </div> */}
    </>
  );
}

export default Timeline;
